import styles from './Footer.module.css'

import Privacy from './privacy_policy.pdf'
import Terms from './terms_and_conditions.pdf'

const Footer = (props) => {
  return(
    <div className={styles.container} style={{position: 'relative', zIndex: 1000000}}>
      <a href={Privacy} target="_blank" rel="noopener noreferrer">
        <div className={styles.section}>
          {props.lang === 'en' ? 'Privacy Policy' : 'Política de Privacidad'}
        </div>
      </a>
      <div className={styles.splitter}>|</div>
      <a href={Terms} target="_blank" rel="noopener noreferrer">
        <div className={styles.section}>
          {props.lang === 'en' ? 'Terms & Conditions' : 'Términos y Condiciones'}
        </div>
      </a>
    </div>
  )
}

export default Footer