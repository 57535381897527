import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import './index.css';
import ReactDOM from 'react-dom';
import HomeDesktop from './pages/HomeDesktop';
import HomeMobile from './pages/HomeMobile';
import Loading from './Loading';
import ReactGA from 'react-ga';
import Countdown from './pages/Countdown';

ReactGA.initialize(process.env.REACT_APP_GA_KEY);
ReactGA.pageview('/');

function App() {
  const [showContent, setShowContent] = useState(false);
  const targetDate = new Date('2023-06-11T10:00:00Z'); // Set your specific target date and time here

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) { 
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (Object.keys(timeLeft).length === 0) {
      clearTimeout(timer);
      handleCountdownComplete();
    }

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const handleCountdownComplete = () => {
    setShowContent(true);
  };

  return (
    <React.StrictMode>
      {!showContent ? (
        <Countdown timeLeft={timeLeft} onComplete={handleCountdownComplete} />
      ) : (
        <>
          <BrowserView>
            <HomeDesktop />
          </BrowserView>
          <MobileView>
            <HomeMobile />
          </MobileView>
        </>
      )}
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
