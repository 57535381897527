
import fondo from '../../assets/the_game/Fondo2.png'
import robotPequeño from '../../assets/the_game/RobotDetras2.png'
import robotIntermedio from '../../assets/the_game/RobotIntermedio.png'
import caravana from '../../assets/the_game/Caravana.png'
import glowRobot from '../../assets/the_game/GlowRobot.png'

//import styles from './Thegame.module.css'
import { useInView } from 'react-intersection-observer'

import { Button } from "react-bootstrap";


const Thegame = (props) => {
  const viewController = {
    main2: useInView({ threshold: .25, triggerOnce: false }),
  }

  if (props.mobile) {
    return (
      <section style={{ width: '100vw', padding: '15vh 0 0 0', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
        <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ fontWeight: 700, fontSize: '2.8vh', color: 'var(--light-blue)' }}>
          {props.lang === 'en' ? 'THE GAME' : 'EL JUEGO'}
          </div>
          <div style={{ fontWeight: 700, fontSize: '3.5vh', marginBottom: '4vh' }}>
            {props.lang === 'en' ? 'WHAT IS OXYSPACE?' : '¿QUÉ ES OXYSPACE?'}
          </div>
          <div style={{ fontWeight: 500, fontSize: '0.8rem', marginBottom: '2rem', textAlign: 'left' }}>
            {props.lang === 'en' ? "Welcome to OxySpace, the exciting free mobile game where you'll embark on a thrilling journey through unexplored territories in space. Forge your own path with a customizable robot, battle in unique biomes using powerful skills, and dominate in intense multiplayer battles." : 'Bienvenido a OxySpace, el emocionante juego gratuito para móviles en el que emprenderás un emocionante viaje a través de territorios inexplorados en el espacio. Forja tu propio camino con un robot personalizable, lucha en biomas únicos usando habilidades poderosas y domina en intensas batallas multijugador.'}
          </div>
          <div style={{ width: '100%', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', overflow: 'hidden' }}>
            <img src={caravana} style={{ width: '90%', transform: 'translateX(2%)' }} />
          </div>
        </div>
      </section>
    )
  }

  return (
    <section id='thegame' style={{ width: '100%', position: 'relative' }}>
      <section
        style={{

          width: '80vw',
          height: '50vh',
          paddingTop: '30vh',
          paddingBottom: '10vh',
          paddingRight: '10vw',
          paddingLeft: '10vw',
          position: 'relative',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: 'Righteous',
          textTransform: 'uppercase',
        }}
      >
        <div
        ref={viewController.main2.ref}
          style={{ 
            maxWidth: '36vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            transition: '.8s ease-in-out',
            zIndex: '1'
          }}
        >
            <div style={{ transform: `translate(0, ${viewController.main2.inView ? 0 : -50}%)`, opacity: viewController.main2.inView ? 1 : 0, transition: '.8s ease-in-out' }}>
              <div style={{ fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh' }}>
                {props.lang === 'en' ? 'THE GAME' : 'EL JUEGO'}
              </div>
              <div style={{ fontWeight: '700', fontSize: '2.8rem', marginBottom: '4vh' }}>
                {props.lang === 'en' ? 'WHAT IS OXYSPACE?' : 'QUÉ ES OXYSPACE?'}
              </div>
              <div style={{ fontWeight: 500, fontSize: '1rem', marginBottom: '2rem', textAlign: 'left' }}>
                {props.lang === 'en' ? 
                  "Welcome to OxySpace, the exciting free mobile game where you'll embark on a thrilling journey through unexplored territories in space. Forge your own path with a customizable robot, battle in unique biomes using powerful skills, and dominate in intense multiplayer battles." 
                  :
                  'Bienvenido a OxySpace, el emocionante juego gratuito para móviles en el que emprenderás un emocionante viaje a través de territorios inexplorados en el espacio. Forja tu propio camino con un robot personalizable, lucha en biomas únicos usando habilidades poderosas y domina en intensas batallas multijugador.'
                }
              </div>
              {/* <div style={{ fontWeight: 500, fontSize: '1rem', marginBottom: '2rem', textAlign: 'left' }}>
                {props.lang === 'en' ? 
                  'You were forced to leave your home planet, taking with you oxy tools and parts. Now you must settle on an unknown planet and explore it under the control of your creations.' 
                  :
                  'Te viste obligado a abandonar tu planeta de origen, llevándote contigo herramientas y piezas oxy. Y no te queda otra que asentarte en un planeta desconocido y explorarlo bajo el control de tus creaciones.'
                }
              </div> */}
              <a
                href={'https://oxyspace.app/hype'}
                rel='noopener noreferrer'
                target='_blank'
                style={{
                  display: 'inline-block',
                  border: '2px solid var(--light-blue)',
                  padding: '0.2rem 0.4rem',
                  borderRadius: '1rem',
                  boxShadow: '0 0 5px var(--light-blue), inset 0 0 5px var(--light-blue)',
                }}
              >
                <div style={{fontSize: '.6rem', textAlign: 'center'}}>
                  {props.lang === 'en' ? 'READ MORE' : 'LEER MÁS'}
                </div>
              </a>
              
            </div>
        </div>

        <div style={{ opacity: viewController.main2.inView ? 1 : 0, transform: `scale(${viewController.main2.inView ? 1 : .88})`, transition: '.9s ease-in-out' }}>
          <img src={caravana} style={{
            filter: "blur(20px)",
            width: '25vw',
            objectFit: 'contain',
            position: 'absolute',
            left: '5vw',
            top: '50%',
            transform: 'translate(0, -47.5%)'
          }} />
          <img src={caravana} style={{
            width: '25vw',
            objectFit: 'contain',
            position: 'absolute',
            left: '5vw',
            top: '50%',
            transform: 'translate(0, -47.5%)'
          }} />
          <img
          style={{
            position: "relative",
            left: '60vw',
            top: '50%',
            width: '15vw',
            objectFit: "cover",
            zIndex: -2,
            transform: 'translate(0, -70%)'
          }}
          alt=""
          src={robotPequeño}
          />
          
        </div>

        <div style={{ position: "absolute",left: '74vw', top: '100%', 
        opacity: viewController.main2.inView ? 0 : 1, transform: `scale(${viewController.main2.inView ? 1 : 1})`, transition: '1.8s ease-in-out' }}>

        <img
          style={{
            position: "absolute",
            
            width: '30vw',
            objectFit: "cover",
            zIndex: 1,
            transform: 'translate(0, -60%)',
            transform: 'rotate(90deg)'
          }}
          src={glowRobot}
          />
          <img
          style={{
            position: "absolute",
            width: '20vw',
            objectFit: "cover",
            zIndex: 1,
            transform: 'translate(33%, -8%)',
          }}
          src={robotIntermedio}
        />
      </div>
      </section>
      <div style={{position: 'absolute', backgroundImage: 'linear-gradient(to bottom, rgb(39 12 48), transparent)', zIndex: 100, width: '100%', height: '32vh', top: 0}}/>
      <div style={{position: 'absolute', backgroundImage: 'linear-gradient(0turn, transparent, rgb(39 12 48), transparent)', width: '100%', height: '20vh', bottom: -100}}/>
    </section>
  )
}

export default Thegame

