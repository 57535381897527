import discordLogo from '../../assets/socials/Discord_logo.png'
import instagramLogo from '../../assets/socials/Instagram_logo.png'
import telegramLogo from '../../assets/socials/Telegram_logo.png'
import twitterLogo from '../../assets/socials/Twitter_logo.png'
import tiktokLogo from '../../assets/socials/Tiktok_logo.png'
import twitchLogo from '../../assets/socials/Twitch_logo.png'

import styles from './Socials.module.css'

const SOCIAL_PAGES = [
  {
    name: 'Discord',
    logo: discordLogo,
    link: 'https://discord.gg/HbXDMEmYRz',
  },
  {
    name: 'Twitter',
    logo: twitterLogo,
    link: 'https://twitter.com/oxyspace_app',
  },
  {
    name: 'Instagram',
    logo: instagramLogo,
    link: 'https://www.instagram.com/oxyspace.app/',
  },
  {
    name: 'Telegram',
    logo: telegramLogo,
    link: 'https://t.me/joinchat/hIMRf5Z91N1lNDdk',
  },
  {
    name: 'Tiktok',
    logo: tiktokLogo,
    link: 'https://www.tiktok.com/@oxyspace.app',
  },
  {
    name: 'Twitch',
    logo: twitchLogo,
    link: 'https://www.twitch.tv/oxyspace_app',
  },
]

const Socials = (props) => {
  if(props.mobile){
    return(
      <div style={{display: 'flex', gap: '4vw', marginTop: '6vh', zIndex:40}}>
        {
          SOCIAL_PAGES.map((page, index) => {
            if(index === SOCIAL_PAGES.length - 1) return null
            return(
              <a key={index} target='_blank' href={page.link} rel="noopener noreferrer" >
                <div style={{width: '8vw', height: '8vw'}} className={styles.button}>
                  <img src={page.logo} style={{width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer'}}/>
                </div>
              </a>
            )
          })
        }
      </div>
    )
  }
  return(
    <div style={{position: 'fixed', zIndex: 1000000000, right: 0, top: '50%', transform: 'translate(0, -50%)'}}>
      <div style={{backdropFilter: 'blur(1vh)', position: 'relative', padding: '2.5vh', overflow: 'hidden', borderTopLeftRadius: '2vh', borderBottomLeftRadius: '3.5vh'}}>
      <div style={{backgroundColor: 'var(--bg)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, opacity: .5, filter: 'brightness(50%)', zIndex: 10000000000}}/>
      <div style={{position: 'relative', zIndex: 100000000000, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2vh'}}>
        {
          SOCIAL_PAGES.map((page, index) => {
            return(
              <a key={index} target='_blank' href={page.link} rel="noopener noreferrer" >
                <div style={{width: '3.5vh', height: '3.5vh'}} className={styles.button}>
                  <img src={page.logo} style={{width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer'}}/>
                </div>
              </a>
            )
          })
        }
      </div>
      </div>
    </div>
  )
}

export default Socials