import React, { useState, useEffect } from 'react';
import { setDoc, doc, updateDoc, increment, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './Countdown.css';
import Footer from './home/Footer'

const Countdown = ({ timeLeft, onComplete }) => {
  const [email, setEmail] = useState('');
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the page from reloading

    if(localStorage.getItem('hasSubmitted') !== null) {
      setMessage('You have already submitted.');
      return;
    }

    if(email.includes('@') && email.split('@')[1].includes('.')){
      setDoc(doc(db, 'alpha-subscriptions', `SUBSCRIBER-${email}`), {
        email: email
      })
        .then(() => {
          updateDoc(doc(db, 'alpha-count', 'COUNT'), {
            count: increment(1)
          })
            .then(() => {
              setEmail('');
              setMessage('Successfully joined!');
              localStorage.setItem('hasSubmitted', true);
            })
        })
        .catch(() => alert('ERROR', 'Could not access the database'))
    } else {
      alert('ERROR', 'Invalid email')
    }
  }

  const getSubscriberCount = async () => {
    onSnapshot(doc(db, 'alpha-count', 'COUNT'), (doc) => {
      setSubscriberCount(doc.data()?.count)
    })
  }

  useEffect(() => {
    getSubscriberCount()
  }, [])

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="countdown-container">
      <h2 className="countdown-text">
        New Website Coming Soon
      </h2>
      
      <div className="countdown-timer">
        <div className="countdown-item">
          <h1>{days}</h1>
          <p>Days</p>
        </div>
        <div className="countdown-item">
          <h1>{hours}</h1>
          <p>Hours</p>
        </div>
        <div className="countdown-item">
          <h1>{minutes}</h1>
          <p>Minutes</p>
        </div>
        <div className="countdown-item">
          <h1>{seconds}</h1>
          <p>Seconds</p>
        </div>
      </div>
      <h2 className="countdown-text2">
        Be quick and be one of the 100 lucky Alpha players!
      </h2>
      {subscriberCount < 100 ? (
        <form onSubmit={handleSubmit}>
          <div className="email-input" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',  border: '3px solid #7d2cd7', borderRadius: 1000, minWidth: '20vw'}}>
            <input
                maxLength={50}
                type="email"
                placeholder={'Email Address'}
                style={{
                  backgroundColor: 'transparent',
                  fontFamily: 'Averta',
                  fontWeight: '600',
                  lineHeight: '1rem',
                  padding: '.8rem',
                  paddingLeft: 'calc(1rem + 4px)',
                  paddingTop: '.95rem',
                  fontSize: '1rem',
                  height: '0.7rem',
                  color: 'var(--white)',
                  resize: 'none',
                  outline: 'none',
                  border: 'none',
                  flexGrow: 1,
                }}
                value={email}
                onChange={e => setEmail(e.target.value.replace(' ', ''))}
              />
            <div
              style={{borderRadius: 1000, marginRight: '.12rem', width: '4.2rem', height: '2.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#7d2cd7', cursor: 'pointer'}}
              onClick={handleSubmit}
            >
              <h5>JOIN</h5>
            </div>
          </div>
          <p style={{textAlign: 'center'}}>{message}</p>
        </form>
      ) : (
        <p>Sorry, we've reached our limit of 100 alpha testers.</p>
      )}
      <div style={{width: '100vw', position: 'fixed', bottom: '0', overflow: 'hidden'}}><Footer/></div>

    </div>
  );
};

export default Countdown;
