import { useEffect, useState } from 'react'

import fondo from '../../assets/images/FondoBetaAndFooter.png'

import { setDoc, doc, updateDoc, increment, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import { useInView } from 'react-intersection-observer'

const Subscribe = (props) => {
  const [subscriberCount, setSubscriberCount] = useState(0)
  const [email, setEmail] = useState('')

  const viewController = {
    main: useInView({ threshold: 0.25, triggerOnce: true }),
  }

  const handleSubmit = () => {
    if(email.includes('@')){
      if(email.split('@')[1].includes('.')){
        setDoc(doc(db, 'user-subscriptions', `SUBSCRIBER-${email}`), {
          email: email
        })
          .then(() => {
            updateDoc(doc(db, 'subscription-count', 'count'), {
              count: increment(1)
            })
              .then(() => {
                setEmail('')
              })
          })
          .catch(() => alert('ERROR', 'Could not access the database'))
      }
    }else{
      alert('ERROR', 'Invalid email')
    }
  }

  const getSubscriberCount = async () => {
    onSnapshot(doc(db, 'subscription-count', 'count'), (doc) => {
      setSubscriberCount(doc.data()?.count)
    })
  }

  useEffect(() => {
    getSubscriberCount()
  }, [])

  if(props.mobile){
    return(
      <section id='subscribe' style={{width: '100vw', padding: '10vh 0 0 0', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1000}}>
        <div style={{width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{fontWeight: 700, fontSize: '2.25vh', color: 'var(--light-blue)'}}>
            {props.lang === 'en' ? 'COMMUNITY' : 'COMUNIDAD'}
          </div>
          <div style={{fontWeight: 700, fontSize: '3.5vh', marginBottom: '4vh', textAlign: 'center'}}>
            {props.lang === 'en' ? 'Join the Beta!' : 'Únete a la Beta'}
          </div>
          <div style={{textAlign: 'center', fontSize: '2vh', marginBottom: '4vh', lineHeight: '3vh'}}>
            {props.lang === 'en' ? 'Be one of the first to try OxySpace! Enter your email address to receive exclusive news and updates. Check out our Privacy Policy to learn more.' : '¡Sé uno de los primeros en probar OxySpace! Introduce tu correo electrónico para recibir noticias y actualizaciones exclusivas. Consulta nuestra Política de Privacidad para conocer más.'}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '3px solid var(--light-blue)', borderRadius: 1000, minWidth: '24vw'}}>
            <input
              maxLength={50}
              type="text"
              placeholder={props.lang === 'en' ? 'Email Address' : 'Correo electrónico'}
              style={{
                backgroundColor: 'transparent',
                fontFamily: 'Averta',
                fontWeight: '600',
                lineHeight: '1rem',
                padding: '.8rem',
                paddingLeft: 'calc(1rem + 4px)',
                paddingTop: '.95rem',
                fontSize: '1rem',
                height: '1rem',
                color: 'var(--white)',
                resize: 'none',
                outline: 'none',
                border: 'none',
                flexGrow: 1,
              }}
              value={email}
              onChange={e => setEmail(e.target.value.replace(' ', ''))}
            />
            <div
              style={{borderRadius: 1000, marginRight: '.25rem', width: '2.2rem', height: '2.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'var(--light-blue)', cursor: 'pointer'}}
              onClick={() => handleSubmit()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width='60%' height='60%' viewBox="0 0 512 512" fill='var(--white)'>
                <path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/>
              </svg>
            </div>
          </div>
          <div style={{width: '86vw', textAlign: 'center', fontSize: '3.2vw', marginBottom: '2vh', paddingTop: '1vh'}}>
            {props.lang === 'en' ? 'Join our ' : 'Únete a la comunidad de '}
            <a href='https://discord.gg/9Vr4JX38Rt' style={{fontWeight: 700}}>discord</a>
            {props.lang === 'en' ? ' community, share your suggestions, and get access to exclusive content.' : ', comparte tus sugerencias y recibe acceso a contenido exclusivo.'}
          </div>
        </div>
      </section>
    )
  }

  return(
    <section id='subscribe' style={{width: '100%', position: 'relative'}}>
      <div
        style={{
          width: '80vw',
          paddingBottom: '20vh',
          paddingRight: '10vw',
          paddingLeft: '10vw',
          paddingTop: '5vw',
          position: 'relative',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div style={{transform: `translate(0, ${viewController.main.inView ? 0 : 50}%)`, opacity: viewController.main.inView ? 1 : 0, transition: '.8s ease-in-out'}}  ref={viewController.main.ref}>
          <div style={{fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh', textAlign: 'center'}}>
            {props.lang === 'en' ? 'COMMUNITY' : 'COMUNIDAD'}
          </div>
          <div style={{fontWeight: '700', fontSize: '2.25rem', marginBottom: '4vh', textAlign: 'center'}}>
            {props.lang === 'en' ? 'Join the Beta!' : 'Únete a la Beta'}
          </div>
        </div>
        <div style={{opacity: viewController.main.inView ? 1 : 0, transform: `scale(${viewController.main.inView ? 1 : .98})`, transition: '.8s ease-in-out'}}>
          <div style={{width: '36vw', textAlign: 'center', fontSize: '1.2vw', marginBottom: '4vh'}}>
            {/* {props.lang === 'en' ? 'Join our newsletter to receive updates on our latest news and events. We\'re already over ' : 'Únete a nuestro newsletter para recibir actualizaciones sobre nuestras últimas noticias y eventos. Ya somos '}
            <span style={{fontWeight: 700}}>{subscriberCount}</span>
            {props.lang === 'en' ? ' subscribers!' : ' suscriptores!'}
            <div></div> */}
            {props.lang === 'en' ? "Be one of the first to try OxySpace! Enter your email address to receive exclusive news and updates. Check out our Privacy Policy to learn more." : '¡Sé uno de los primeros en probar OxySpace! Introduce tu correo electrónico para recibir noticias y actualizaciones exclusivas. Consulta nuestra Política de Privacidad para conocer más.'}
            
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '3px solid var(--light-blue)', borderRadius: 1000, minWidth: '24vw'}}>
            <input
              maxLength={50}
              type="text"
              placeholder={props.lang === 'en' ? 'Email Address' : 'Correo electrónico'}
              style={{
                backgroundColor: 'transparent',
                fontFamily: 'Averta',
                fontWeight: '600',
                lineHeight: '1rem',
                padding: '.8rem',
                paddingLeft: 'calc(1rem + 4px)',
                paddingTop: '.95rem',
                fontSize: '1rem',
                height: '1rem',
                color: 'var(--white)',
                resize: 'none',
                outline: 'none',
                border: 'none',
                flexGrow: 1,
              }}
              value={email}
              onChange={e => setEmail(e.target.value.replace(' ', ''))}
            />
            <div
              style={{borderRadius: 1000, marginRight: '.25rem', width: '2.2rem', height: '2.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'var(--light-blue)', cursor: 'pointer'}}
              onClick={() => handleSubmit()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width='60%' height='60%' viewBox="0 0 512 512" fill='var(--white)'>
                <path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/>
              </svg>
            </div>
          </div>

          <div style={{width: '36vw', textAlign: 'center', fontSize: '1.2vw', marginBottom: '2vh', paddingTop: '1vh'}}>
            {props.lang === 'en' ? 'Join our ' : 'Únete a la comunidad de '}
            <a href='https://discord.gg/9Vr4JX38Rt' style={{fontWeight: 700}}>discord</a>
            {props.lang === 'en' ? ' community, share your suggestions, and get access to exclusive content.' : ', comparte tus sugerencias y recibe acceso a contenido exclusivo.'}
          </div>

        </div>
      </div>
      {/*<div style={{position: 'absolute', backgroundImage: 'linear-gradient(to top, rgb(39 12 48), transparent)', zIndex: 1, width: '100%', height: '10vh', bottom: -100}}/>
      <div style={{position: 'absolute', backgroundImage: 'linear-gradient(to bottom, rgb(39 12 48), transparent)', zIndex: 1, width: '100%', height: '10vh', top: 0}}/> */}
    </section>
  )
}

export default Subscribe