import { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Stars } from '@react-three/drei'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from './home/Landing'
import Navbar from './home/Navbar'
import Thegame from './home/Thegame'
import Features from './home/Features'
import Subscribe from './home/Subscribe'
import AboutUs from './about/AboutUs'
import Hype from './home/Hype'
import Footer from './home/Footer'
import Socials from './home/Socials'

const HomeMobile = (props) => {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')

  useEffect(() => {
    localStorage.setItem('lang', lang)
  }, [lang])

  return (
    <div style={{width: '100vw', position: 'relative', overflowX: 'hidden'}}>
      <div style={{position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1}}>
        <Canvas>
          <Stars radius={200} depth={50} count={5000} factor={4} saturation={0} fade/>
        </Canvas>
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<>
            <Landing lang={lang} setLang={setLang} mobile={true} />
            <Thegame lang={lang} mobile={true} />
            <Features lang={lang} mobile={true}/>
            {/*<Roadmap lang={lang} mousePosition={mousePosition} /> */}
          </>} />
          <Route path="/about" element={<AboutUs lang={lang} mobile={true}/>} />
          <Route path="/hype" element={<Hype lang={lang} mobile={true}/>} />
        </Routes>
        <Hype lang={lang}/>
        <Subscribe lang={lang} mobile={true} />
        <Footer lang={lang} mobile={true}/> 
      </Router>
    </div>
  )
}

export default HomeMobile