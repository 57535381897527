import meteor2 from '../../assets/images/Meteor2.png'
import meteorRaro from '../../assets/features/MeteorRaro.png'

import lineStats from '../../assets/features/LineStats.png'
import mascotsLineSvg from '../../assets/features/ExclusiveMascots.svg'
import mascotRuins from '../../assets/features/bichoRuins.png'
import mascotMagic from '../../assets/features/bichoMagic.png'
import biomaFuego from '../../assets/features/SinFondo_Fuego.png'
import biomaMagic from '../../assets/features/SinFondo_Magic.png'
import biomaRaro from '../../assets/features/SinFondo_raro.png'
import biomaBosque from '../../assets/features/SinFondoBosque.png'
import biomaRocas from '../../assets/features/SinFondo_Rocas.png'

//import styles from './Thegame.module.css'
import styles from "./Features.module.css";

import { Button } from "react-bootstrap";
import { useInView } from 'react-intersection-observer'


const Features = (props) => {
  const SECTIONS = [
    {
      inView: useInView({ threshold: 0.25, triggerOnce: false }),
      title: {
        en: 'CUSTOMIZATION',
        es: 'PERSONALIZACIÓN',
      },
      content: {
        en: [
          [
            "Experience ultimate freedom and endless possibilities in OxySpace! Customize your robot with biome unique pieces and choose from a variety of powerful weapons to create a playstyle that suits you best. Whether you prefer to be a DPS, Tank, Bolt, or Support, the choice is yours.",
          ]
        ],
        es: [
          [
            'Experimenta la máxima libertad y posibilidades infinitas en OxySpace. Personaliza tu robot con piezas únicas de biomas y elige entre una variedad de armas poderosas para crear un estilo de juego que se adapte mejor a ti. Ya sea que prefieras ser DPS, Tanque, Bolt o Apoyo, la elección es tuya.',
          ]
        ],
        embed: <iframe src='https://my.spline.design/untitled-954c65c2d34808b1267465b28152f245/' frameborder='0' width='966' height='714'></iframe>,
        imageExtra:<img src={biomaRocas}
        style={{
          width: '25vw',
          objectFit: 'contain',
          position: 'absolute',
          left: '-10vw',
          bottom: '-75%',
          transform: 'translate(0, -57.5%)',
          opacity: '80%',
          filter: "blur(2px)",
        }}
      />,
      rocaFloat:<img src={meteor2}
        style={{
          width: '5vw',
          objectFit: 'contain',
          position: 'absolute',
          right: '15vw',
          bottom: '-25%',
          transform: 'translate(0, -57.5%)',
          opacity: '70%',
          filter: "blur(2px)",
        }}
        className={styles.meteor1}
      />,
      rocaFloat2:<img src={meteorRaro}
        style={{
          width: '7vw',
          objectFit: 'contain',
          position: 'absolute',
          right: '25vw',
          bottom: '-20%',
          transform: 'translate(0, -57.5%)',
          opacity: '70%',
          filter: "blur(1px)",
        }}
        className={styles.meteor2}
      />
      },
      url: {
        en: 'https://oxyspace.app/hype',
        es: 'https://oxyspace.app/hype',
      },
    },
    {
      inView: useInView({ threshold: 0.25, triggerOnce: false }),
      title: {
        en: 'BIOMES SYSTEM',
        es: 'SISTEMA BIOMAS',
      },
      content: {
        en: [
          [
            'The biome system in OxySpace delivers an immersive gaming experience with battles taking place in ruins, jungles, or even magical lands, each with their own challenges and exclusive rewards. Customizable Biome-unique pieces and features allow you to adapt your robot to each biome, offering endless possibilities for gameplay.'
          ]
        ],
        es: [
          [
            'El sistema de biomas en OxySpace proporciona una experiencia de juego inmersiva con batallas que tienen lugar en ruinas, junglas o incluso tierras mágicas, cada una con sus propios desafíos y recompensas exclusivas. Las piezas y características únicas de cada bioma que se pueden personalizar permiten que adaptes tu robot a cada bioma, ofreciendo posibilidades infinitas para el juego.'
          ]
        ],
        // image: <img src={oxyImg} style={{height: '80vh', objectFit: 'contain', position: 'absolute', left: '20vw', top: '50%', transform: 'translate(0, -65%)'}}/>,
        image: <img src={biomaBosque} style={{width: '20vw', objectFit: 'contain', position: 'absolute', left: '20vw', top: '50%', transform: 'translate(0, -57.5%)'}}/>,
        image2:<img src={biomaFuego}
        style={{
          width: '14vw',
          objectFit: 'contain',
          position: 'absolute',
          left: '12vw',
          bottom: '-13%',
          transform: 'translate(0, -57.5%)',
          opacity: '90%',
          filter: "blur(1px)",
        }}
        />,

        image3:<img src={biomaMagic}
          style={{
            width: '10vw',
            objectFit: 'contain',
            position: 'absolute',
            left: '35vw',
            bottom: '0%',
            transform: 'translate(0, -47.5%)',
            opacity: '85%',
            filter: "blur(1px)",
          }}
        />,
        
        image4:<img src={biomaRaro}
          style={{
            width: '8vw',
            objectFit: 'contain',
            position: 'absolute',
            left: '42vw',
            bottom: '30%',
            transform: 'translate(0, -37.5%)',
            opacity: '70%',
            filter: "blur(1.5px)",
          }}
        />
      },
      url: {
        en: 'https://oxyspace.app/hype',
        es: 'https://oxyspace.app/hype',
      },
    }
  ]
  if(props.mobile){
    return(
      <section style={{ width: '100vw', padding: '15vh 0 0 0', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
        <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ fontWeight: 700, fontSize: '2.8vh', color: 'var(--light-blue)' }}>
            {props.lang === 'en' ? 'FEATURES' : 'MECANICAS'}
          </div>
          <div style={{ fontWeight: 700, fontSize: '3.5vh', marginBottom: '4vh' }}>
            {props.lang === 'en' ? 'CUSTOMIZATION' : 'PERSONALIZACIÓN'}
          </div>
          <div style={{ fontWeight: 400, fontSize: '0.8rem', marginBottom: '2rem', textAlign: 'left' }}>
            {props.lang === 'en' ? "Experience ultimate freedom and endless possibilities in Oxyspace! Customize your robot with biome unique pieces and choose from a variety of powerful weapons to create a playstyle that suits you best. Whether you prefer to be a DPS, tank, bolt, or support, the choice is yours." : 'Experimenta la máxima libertad y posibilidades infinitas en Oxyspace. Personaliza tu robot con piezas únicas de biomas y elige entre una variedad de armas poderosas para crear un estilo de juego que se adapte mejor a ti. Ya sea que prefieras ser DPS, tanque, bolt o apoyo, la elección es tuya.'}
          </div>
        </div>
        <div style={{ width: '350px', height: "350px" }}>
          <iframe src='https://my.spline.design/oxychangemobilecopy-97b08b5d9d55c1a5f072966a3cc50760/' frameborder='0' transform="translate(-160px, -218px)" width='100%' height='100%'></iframe>
        </div>
      </section>
    )
  }
    return (
      <section id='features' style={{width: '100%', position: 'relative'}}>
        {
          SECTIONS.map((section, index) => {
            return(
              section.content !== undefined
              ?
              (
                <section
                  key={index}
                  style={{
                    width: '80vw',
                    height: index === 0 ? '50vh' : '90vh',
                    paddingTop: index === 0 ? '30vh' : '0',
                    paddingBottom: '10vh',
                    paddingRight: '10vw',
                    paddingLeft: '10vw',
                    position: 'relative',
                    zIndex: 100,
                    display: 'flex',
                    flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontFamily: 'Righteous',
                    textTransform: 'uppercase',
                  }}
                >
                  <div
                    ref={section.inView.ref}
                    style={{
                      maxWidth: '36vw', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'flex-start', 
                      alignItems: index % 2 === 0 ? 'flex-start' : 'flex-start',
                      opacity: section.inView.entry?.isIntersecting ? 1 : 0, 
                      transition: '.8s ease-in-out',
                      transform: `translateX(${section.inView.entry?.isIntersecting ? 0 : index % 2 === 0 ? '-25%' : '25%'})`,
                    }}
                  >
                    
                    <div style={{fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh'}}> {/*DEBERIA SACARLO DEL DIV*/}
                      {props.lang === 'en' ? 'FEATURES' : 'MECANICAS'}
                    </div>
                    <div style={{fontWeight: '700', fontSize: '2.8rem', marginBottom: '4vh', textAlign: index % 2 === 0 ? 'start' : 'start'}}>
                      {props.lang === 'en' ? section.title.en : section.title.es}
                    </div>
                    <div style={{position: 'relative'}}>
                      {
                        props.lang === 'en'
                        ?
                        section.content.en[0].map((content, index) => {
                          return(
                            <div
                              key={index}
                              style={{
                                fontSize: '1rem', marginBottom: '2rem', fontWeight: '500',
                                textAlign: SECTIONS.indexOf(section) % 2 === 0 ? 'left' : 'left', //Primero alineado a la izquierda segundo texto a la derecha
                              }}
                            >
                              {content}
                            </div>
                          )
                        })
                        :
                        section.content.es[0].map((content, index) => {
                          return(
                            <div
                              key={index}
                              style={{
                                fontSize: '1rem', marginBottom: '2rem', fontWeight: '500',
                                textAlign: SECTIONS.indexOf(section) % 2 === 0 ? 'left' : 'left', //Primero alineado a la izquierda segundo texto a la derecha
                              }}
                            >
                              {content}
                            </div>
                          )
                        })
                      }
                    </div>
                      {
                        section.url !== undefined
                        &&
                        <a
                          href={props.lang === 'en' ? section.url.en : section.url.es} //BOTONES READMORE
                          rel='noopener noreferrer'
                          target='_blank'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid var(--light-blue)',
                            height: '1rem',
                            padding: '0.2rem 0.4rem',
                            borderRadius: '1rem',
                            boxShadow: '0 0 5px var(--light-blue), inset 0 0 5px var(--light-blue)',
                          }}
                        >
                          {/* fontWeight managed by parent's css class */}
                          <div style={{fontSize: '.6rem', textAlign: 'center'}}>
                            {props.lang === 'en' ? 'READ MORE' : 'LEER MÁS'}
                          </div>
                        </a>
                      }
                  </div>
                  <div
                  style={{
                    transition: '1s ease-in-out',
                    opacity: section.inView.entry?.isIntersecting ? 1 : 0, //Animacion div imagen
                  }}
                  >
                  {section.content.image}
                  {section.content.embed}
                  {section.content.imageExtra}
                  {section.content.rocaFloat}
                  {section.content.rocaFloat2}
                </div>
                

                <div
                  style={{
                    transition: '1s ease-in-out', transitionDelay: '0.8s',
                    opacity: section.inView.entry?.isIntersecting ? 1 : 0, //Animacion div imagen
                  }}
                  >
                  {section.content.image2}
                </div>

                <div
                  style={{
                    transition: '1s ease-in-out', transitionDelay: '0.6s',
                    opacity: section.inView.entry?.isIntersecting ? 1 : 0, //Animacion div imagen
                  }}
                  >
                  {section.content.image3}
                </div>

                <div
                  style={{
                    transition: '1s ease-in-out', transitionDelay: '0.4s',
                    opacity: section.inView.entry?.isIntersecting ? 1 : 0, //Animacion div imagen
                  }}
                  >
                  {section.content.image4}
                </div>

                </section> //Seccion imagen
              )
              :
              ( //VERSION MOVILLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL MOVILLLLLLLLLLL
                <section
                  key={index}
                  style={{
                    width: '100vw',
                    height: '350vh',
                    position: 'relative',
                    zIndex: 100,
                  }}
                >
                  <div
                    style={{
                      position: (props.windowOffset >= 2.9 * window.innerHeight && props.windowOffset < 5.5 * window.innerHeight) ? 'fixed' : 'absolute',
                      top: props.windowOffset >= 5.5 * window.innerHeight ? 'unset' : '0',
                      bottom: props.windowOffset >= 5.5 * window.innerHeight ? '0' : 'unset',
                      left: 0,
                      width: '100vw',
                      height: '100vh',
                      zIndex: 1000,
                      overflow: 'hidden',
                    }}
                  >
                    <div ref={section.inView.ref} style={{position: 'absolute', top: '10vh', left: '50%', transform: `translate(-50%, ${section.inView.entry?.isIntersecting ? 0 : 50}%)`, opacity: section.inView.entry?.isIntersecting ? 1 : 0, transition: '.8s ease-in-out'}}>
                      <div style={{fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh', textAlign: 'center'}}>
                        {props.lang === 'en' ? 'FUERA DIV MOBIL?' : 'EL JUEGO'}
                      </div>
                      <div style={{fontWeight: '700', fontSize: '2.25rem', marginBottom: '6vh', textAlign: 'center'}}>
                      {
                        props.lang === 'en'
                        ?
                        SECTIONS[2].subsections[
                          props.windowOffset <= 3.5 * window.innerHeight ? 0 :

                          props.windowOffset <= 4 * window.innerHeight ? 1 :

                          props.windowOffset <= 4.5 * window.innerHeight ? 2 :
                          3
                        ].title.en
                        :
                        SECTIONS[2].subsections[
                          props.windowOffset <= 3.5 * window.innerHeight ? 0 :

                          props.windowOffset <= 4 * window.innerHeight ? 1 :

                          props.windowOffset <= 4.5 * window.innerHeight ? 2 :
                          3
                        ].title.es
                      }
                      </div>
                    </div>
            
                    <div style={{width: '100%', height: '16vh', position: 'absolute', bottom: '0', backgroundImage: 'linear-gradient(to top, #04060a, transparent)', zIndex: 100000}}/>
                  </div>
                  <div style={{width: '100%', height: '16vh', position: 'absolute', bottom: '-16vh', backgroundImage: 'linear-gradient(to bottom, #04060a, transparent)', zIndex: 100}}/>
                </section>
              )
            )
          })
        }
        
      </section>
      )
    }
      

export default Features;
